'use strict';

document.addEventListener('DOMContentLoaded', function() {
    startMiniApp({
        hourlyRateDisplay: model => model.hourlyRate,
        hoursPerDayDisplay: model => model.hoursPerDay,
        hoursPerDaySubtotal: [model => model.hourlyRate * model.hoursPerDay, formatNumber],
        daysPerWeekDisplay: model => model.daysPerWeek,
        daysPerWeekSubtotal: [model => model.hoursPerDaySubtotal() * model.daysPerWeek * 4, formatNumber],
        serviceFeeDisplay: model => (100 - model.serviceFee) / 100,
        serviceFeeSubtotal: [model => model.daysPerWeekSubtotal() * ((100 - model.serviceFee) / 100), formatNumber],
        exchangeRateDisplay: model => model.exchangeRate,
        exchangeRateSubtotal: [model => model.serviceFeeSubtotal() * model.exchangeRate, formatNumber],
        taxRateDisplay: model => (100 - model.taxRate) / 100,
        taxRateSubtotal: [model => model.exchangeRateSubtotal() * ((100 - model.taxRate) / 100), formatNumber],
        monthlySalaryDisplay: [model => model.taxRateSubtotal(), n => formatNumber(n, 0)],
        monthlySalaryCurrency: model => model.currency,
    });

    function formatAll(format, before) {
        const after = {};
        Object.keys(before).forEach(property => {
            const calculate = before[property];
            after[property] = {
                calculate: calculate,
                format: format,
            };
        });
        return after;
    }

    function formatNumber(x, decimals) {
        if (typeof x !== 'number') return x;
        else return delimitNumber(roundNumber(x, decimals));
    }

    function roundNumber(x, decimals) {
        return Number.isInteger(x) ? String(x) : x.toFixed(decimals);
    }

    function delimitNumber(x) {
        const integer = x.split('.')[0];
        const fraction = x.split('.')[1];
        // Going from the back, we want to place a separator before every digit
        // (in this case "to the right of") except for the first one (in this
        // case "the rightmost one").
        const indexes = range(1, integer.length - 1) // All indexes.
            .filter(i => i % 3 === 0) // Every third index.
            .map(i => integer.length - 1 - i); // Invert to count from the right.
        const chars = integer.split('');
        const delimitedInteger = chars.reduce((s, c, i) => {
            return s + (indexes.includes(i) ? c + ',' : c);
        }, '');
        return fraction ? delimitedInteger + '.' + fraction : delimitedInteger;
    }

    function range(start, end) {
        const values = [];
        for (let i = start; i <= end; i += 1) {
            values.push(i);
        }
        return values;
    }
});
